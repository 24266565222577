import React, { useState, useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import axios from 'axios';

const Navbar = ({ bgColorClass }) => {
  const [scrolling, setScrolling] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logoContent, setLogoContent] = useState(null);
  const [bgColor, setBgColor] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const subDomain = localStorage.getItem('subDomain');
  let schoolName = subDomain;

  if (subDomain.includes('virtupreplabs')) {
    schoolName = 'torah';
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initialize based on current window width

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getNavbarDetails', {
          params: { school: schoolName },
        });
        if (response.data.success === false) {
          setError(response.data.message);
        } else {
          setData(response.data);
          setBgColor(response.data.backgroundColor);
          setFontColor(response.data.fontColor);
  
          const logoUrl = response.data.logo.logo;
          if (logoUrl.endsWith('.svg')) {
            fetchAndModifySVG(logoUrl, response.data.fontColor);  // Pass fontColor here
          } else {
            setLogoContent(logoUrl);  // For PNG/JPEG, set the image URL directly
          }
        }
      } catch (err) {
        setError("Error loading data");
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [schoolName]);
  
  const fetchAndModifySVG = async (svgUrl, fontColor) => {  // Accept fontColor as a parameter
    try {
      const response = await fetch(svgUrl);
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
      const svgElement = svgDoc.querySelector('svg');
      
      if (svgElement) {
        const paths = svgElement.querySelectorAll('path');
        paths.forEach(path => {
          path.setAttribute('fill', fontColor);   // Use the passed fontColor
          path.setAttribute('stroke', fontColor); 
        });
        svgElement.setAttribute('width', '50px');
        svgElement.setAttribute('height', '20px');
        const serializer = new XMLSerializer();
        const modifiedSvgText = serializer.serializeToString(svgElement);
        setLogoContent(modifiedSvgText);
      }
    } catch (error) {
      console.error('Error fetching or modifying the SVG:', error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setScrolling(currentScrollPos > 0);
    };

    const handleDocumentClick = (e) => {
      if (!isNavbarCollapsed && !e.target.closest('.navbar')) {
        setIsNavbarCollapsed(true);
      }
      if (openDropdownIndex !== null && !e.target.closest('.dropdown-menu') && !e.target.closest('.nav-item')) {
        setOpenDropdownIndex(null);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isNavbarCollapsed, openDropdownIndex]);

  const handleNavbarToggle = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleJoinClick = () => {
    const joinLink = `https://${subDomain}.app.virtupreplabs.com/join`;
    window.open(joinLink, '_blank');
  };

  const handleLoginClick = () => {
    const loginLink = `https://${subDomain}.app.virtupreplabs.com`;
    window.open(loginLink, '_blank');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <nav className={`navbar navbar-expand-lg position-fixed ht-10vh`} style={{ backgroundColor: bgColor, color: fontColor }}>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
        <div className='logo-container d-inline-flex align-items-center gap-0 maxw-265 w-100 me-xl-5 me-0'>
          <div className="siteLogo">
            <a href="/">
              {subDomain === "virtupreplabs" ? (
                <img src="/logos/VP3d.svg" alt="Logo" width="50px" height="20px" />
              ) : (
                logoContent ? (
                  logoContent.includes('<svg') ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: logoContent }}
                      className="svg-rotate"
                      style={{ display: 'block' }}
                    />
                  ) : (
                    <img src={logoContent} alt="Logo" width="50px" height="20px" />
                  )
                ) : (
                  <i className={data.logo.iconClass}></i>
                )
              )}
            </a>
          </div>
          <a className="common-textshadow montser-lrg ms-2 text-decoration-none" 
              style={{color: subDomain === "virtupreplabs" ? "#ffe6c8" : fontColor}} 
              href={subDomain === "virtupreplabs" ? "/" : data.brand.href}>
            <span className='montser-lrg common-textshadow'>{subDomain === "virtupreplabs" ? "Virtu Prep" : data.brand.name}</span>
          </a>
        </div>
        <button
          className="navbar-toggler border-0"
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded={!isNavbarCollapsed}
          aria-label="Toggle navigation"
          onClick={handleNavbarToggle}
        >
          <span className={`${scrolling ? 'text-white' : ''}`}>
            <span className="f-ui-1 montser-lrg common-textshadow" style={{color: subDomain === "virtupreplabs" ? "#ffe6c8" : fontColor}}>Menu</span>
          </span>
        </button>
        <div className={`collapse navbar-collapse mobile-menu mobile-bg-color ${!isNavbarCollapsed ? 'show' : ''}`} id="navbarSupportedContent" 
         style={isMobile ? { backgroundColor: localStorage.getItem("heroFontColor") } : {}} >
          <ul className="navbar-nav d-flex align-items-start align-items-lg-center center me-auto mb-2 mb-lg-0 header-txt-margin gap-4">
            {data.menu.map((item, index) => {
              if (item.name === "Writings" && subDomain !== "virtupreplabs") {
                return null;
              }
              return (
                <li className="nav-item" key={index}>
                  {!item.subMenu ? (
                    <a className="d-flex align-items-center navbar-brand common-textshadow montser-lrg m-0" href={item.href} style={{color: subDomain === "virtupreplabs" ? "#ffe6c8" : fontColor}}>
                      <span className='text-decorator-underline text-wrap' style={isMobile ? { color: localStorage.getItem("herobgColor") } : {}} >{item.name}</span>
                    </a>
                  ) : (
                    <div
                      className="d-flex align-items-center gap-2 text-yellow montser-mdm-2 common-textshadow"
                      id={`navbarDropdown${index}`}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => handleDropdownToggle(index)}
                    >
                      <span className='navbar-brand common-textshadow montser-lrg m-0 nav-item-span nav-context-menu-title' 
                      style={{
                        color: subDomain === "virtupreplabs" 
                            ? "#ffe6c8" 
                            : (isMobile ? localStorage.getItem("herobgColor") : undefined)
                    }}
                      >{item.name}</span>
                      <div className={`${openDropdownIndex === index ? 'rotate-upwards d-flex align-center' : 'd-flex align-center'}`}>
                        <i className='demo-icon icon-opened-folder dropdown-icon-size' style={{color:fontColor}}></i>
                      </div>
                    </div>
                  )}
                  {item.subMenu && (
                    <ul className={`dropdown-menu ${openDropdownIndex === index ? 'show' : ''}`} aria-labelledby={`navbarDropdown${index}`} style={{backgroundColor:localStorage.getItem("heroFontColor")}}>
                       {item.subMenu.map((subItem, subIndex) => {
                        const hrefPrefix = item.name === "Features" ? "feature" : "educator";
                        return (
                          <li className="ms-4" key={subIndex}>
                            <a className="dropdown-item-effect text-yellow montser-lrg common-textshadow"  href={`/${hrefPrefix}=${subItem.name}`}>
                              <span className='text-decorator-underline' style={{color:localStorage.getItem("herobgColor")}}>{subItem.name}</span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <div className="d-flex gap-3 justify-content-end">
            <a className="btn-login btn common-btn common-textshadow montser-lrg text-decoration-none" onClick={handleLoginClick} 
              style={{
                color: subDomain === "virtupreplabs" 
                  ? "#ffe6c8" 
                  : (isMobile ? localStorage.getItem("herobgColor") : undefined),
                marginTop: isMobile ? "1.5rem" : undefined // Conditional marginTop
              }}>
              Login
            </a>
            <a 
                className="btn-join btn common-btn common-textshadow montser-lrg text-decoration-none" 
                onClick={handleJoinClick}
                style={{
                  color: subDomain === "virtupreplabs" 
                    ? "#ffe6c8" 
                    : (isMobile ? localStorage.getItem("herobgColor") : undefined),
                  marginTop: isMobile ? "1.5rem" : undefined // Conditional marginTop
                }}
              >
              Join
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useEffect, useState } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import './aboutus.css';
import VideoBackground from '../components/VideoBackground';
import axios from 'axios';

const Aboutus = () => {
  const pageName = "aboutPage";
  const buttonText = "Home";

  ////////axios api/////////
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const subDomain = localStorage.getItem('subDomain');
  let schoolName = subDomain;

  if (subDomain.includes('virtupreplabs')) {
    schoolName = 'torah';
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getAboutUsPageDetails', {
          params: { school: schoolName },
        });
        if (response.data.success == false) {
          setError(response.data.message);
        } else {
          setData(response.data);
        }
      } catch (err) {
        setError("Error loading about us page details");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    const elements = document.getElementsByClassName("text-yellow");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("aboutSectionActive");
  }, [schoolName]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!data || data === " ") return <div>...Loading</div>;

  return (
    <div className='p-0 mb-5'>
      {data && (
        <VideoBackground
          videoLink={data.videoLink}
          overlayText={data.overlayText}
          buttonText={buttonText}
          pageName={pageName}
        />
      )}
      <div
        style={{
          height: '100%',
          paddingTop: '20px',
          background: data.backgroundColor,
          color: data.fontColor,
        }}
        className=''
      >
        <div className="container-fluid vp-container gap-3 gap-md-5 no-flex mt-5 mb-5">
          <div className='content-margin'>
            {data && data.sections.map((section, index) => (
              <div key={index} className='section-content'>
                {/* Center-aligned heading */}
                <h4 className='section-heading text-center montser-mdm-1-5rem common-textshadow'>
                  {section.heading}
                </h4>
                {/* Left-aligned paragraphs */}
                {section.paragraphs.map((paragraph, idx) => (
                  <div key={idx} className='para text-left mb-5'>
                    {paragraph
                      .split(/(\n+)/) // Split by newline, keeping \n
                      .map((part, partIdx) => {
                        // Check if the part is made of only \n
                        const isNewline = part.match(/\n+/);
                        return (
                          <React.Fragment key={partIdx}>
                            {/* Render non-newline text normally */}
                            {!isNewline && (
                              <p className='paragraph-text montser-sml-LightItalic common-textshadow'>
                                {part.trim()}
                              </p>
                            )}
                            {/* Add the appropriate amount of spacing for consecutive newlines */}
                            {isNewline && (
                              <div style={{ marginBottom: `${isNewline[0].length}rem` }} />
                            )}
                          </React.Fragment>
                        );
                      })
                    }
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
